/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('ants', function (http, toast) {
    const url = config.apiURL + 'procedures/ants/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    return {
        getANTSProcedureMotifs: () => {
            let _url = url + '/motifs';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        setUnsetANTSProcedure: (procedureId = null, antsProcedure = null, unset = false) => {
            if (!procedureId || (unset === false && !antsProcedure)) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/' + (unset === true ? 'cancel' : 'add');
            return new Promise(function (resolve, reject) {
                http.put(_url, antsProcedure).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        sendANTSProcedure: (procedureId = null, data = null) => {
            if (!procedureId || !data) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/send';
            return new Promise(function (resolve, reject) {
                http.put(_url, data).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        validateANTSProcedure: (procedureId = null, document = null) => {
            if (procedureId === null || document === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/validate';
            return new Promise((resolve, reject) => {
                http.put(_url, document, {
                    headers: {'Content-Type': undefined}
                }).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        }
    };
});